import moment from "moment";

const eventLogCodes = {
  SSO_SIGNIN: 1001,
  SSO_SIGNIN_AUTO_LOGIN: 1002,
  PUBLISHER_CONSENT_ACCEPT: 1003,
  TWO_FA_AUTH: 1004,
  USER_SIGNUP: 1005,
  GET_PROFILE: 1006,
  CLUSTER_SYNC: 1007,
  EMAIL_VALIDATION: 1008,
  TWO_FA_VERIFY: 1010,
  CHECK_PUBLISHER_CONSENT: 1011,
  GET_DOMAIN_CONSENT: 1012,
  ACCOUNT_DELETED: 1013,
  SIGNUP_CONSENT_STATUS: 1014,
  UID2_GENERATED: 2001,
  ID5_GENERATED: 2002,
  RAMPID_GENERATED: 2003,
  AWS_DATA_ENDPOINT: 2004,
  OPTABLE_DATA_ENDPOINT: 2005,
  FIRST_ID_TOKEN: 6001,
  YAHOO_CONNECT_ID: 6060,
};

export const identitProvidersType = {
  UID: "UID",
  RAMPID: "RAMPID",
  ID5: "ID5",
  EUID: "EUID",
  FIRSTID: "FIRSTID",
  YAHOO_CONNECT_ID: "YAHOO_CONNECT_ID",
};

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

const eventLogStrings = {
  SSO_SIGNIN: "Signin",
  SSO_SIGNIN_AUTO_LOGIN: "Signin",
  PUBLISHER_CONSENT_ACCEPT: "Consent",
  TWO_FA_AUTH: "2FA",
  USER_SIGNUP: "Signup",
  GET_PROFILE: "Profile",
  CLUSTER_SYNC: "Sync",
  EMAIL_VALIDATION: "Email Verify",
  TWO_FA_VERIFY: "2FA",
  ACCOUNT_DELETED: "Delete",
  SIGNUP_CONSENT_STATUS: "Signup",
  UID2_GENERATED: "UID2",
  ID5_GENERATED: "ID5",
  RAMPID_GENERATED: "RAMP ID",
  FIRSTID_GENERATED: "FIRST ID",
  AWS_DATA_ENDPOINT: "AWS Data Endpoint",
  OPTABLE_DATA_ENDPOINT: "Optable Data Endpoint",
  FIRST_ID_TOKEN: "FIRSTID",
  YAHOO_CONNECT_ID: "Yahoo Connect ID"
};

export const bulkUploadFileStatus = {
  0: "INITIATED",
  1: "PROCESSING",
  2: "COMPLETED",
  3: "FAILED",
};

function getKeyByValue(object, value) {
  let eventString = Object.keys(object).find((key) => object[key] === value);
  return eventLogStrings[eventString];
}

export function eventLogCodesToString(eventLogCode) {
  return getKeyByValue(eventLogCodes, eventLogCode)?.replaceAll("_", " ");
}

export function getCountryNameFromCode(countryCode) {
  return countryCodes[
    countryCodes.findIndex((code) => code.code === countryCode)
  ].name;
}
const countryCodes = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const validateInput = (input) => {
  let inputArray = input.split(" ");
  for (let i = 0; i < inputArray?.length; i++) {
    if (checkURL(inputArray[i])) {
      return false;
    } else {
      if (isUrl(inputArray[i])) {
        return false;
      }
    }
  }
  return true;
};

export const checkURL = (input) => {
  let url;
  try {
    url = new URL(input);
    return true;
  } catch (e) {
    return false;
  }
};

export function isUrl(value) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(value);
}

export function getColumnsForConnectionLogs(
  type,
  renderActionButtons,
  renderStatus
) {
  if (type === "Snapshot") {
    return [
      {
        title: <span className="app-table-header">Address</span>,
        key: "snowflakeInputTable",
        dataIndex: "snowflakeInputTable",
        render: (_value, record) => (
          <label className="connection-tile-font">{`${record.snowflakeDB} / ${record.snowflakeInputTable}`}</label>
        ),
      },
      {
        title: <span className="app-table-header">Date/Time</span>,
        key: "time",
        dataIndex: "time",
        render: (value) => (
          <label className="connection-tile-font">
            {moment(parseFloat(value)).format("MM/DD/YYYY")}
          </label>
        ),
        align: "right",
      },
      {
        title: <span className="app-table-header">Status</span>,
        key: "status",
        dataIndex: "status",
        render: renderStatus,
        align: "right",
      },
      {
        title: <span className="app-table-header">Actions</span>,
        render: renderActionButtons,
        align: "right",
      },
    ];
  } else {
    return [
      {
        title: <span className="app-table-header">Address</span>,
        key: "snowflakeInputTable",
        dataIndex: "snowflakeInputTable",
        width: "32%",
        render: (_value, record) => (
          <label
            className="connection-tile-font"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >{`${record.snowflakeDB} / ${record.snowflakeInputTable}`}</label>
        ),
      },
      {
        title: <span className="app-table-header">Next Execution</span>,
        key: "nextExecutionTime",
        dataIndex: "nextExecutionTime",
        align: "right",
        render: (value) => (
          <label
            className="connection-tile-font"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            {moment(parseFloat(value)).format("MM/DD/YYYY hh:mm A")}
          </label>
        ),
      },
      {
        title: <span className="app-table-header">Status</span>,
        key: "status",
        dataIndex: "status",
        render: renderStatus,
        align: "right",
      },
      {
        title: <span className="app-table-header">Frequency</span>,
        key: "frequency",
        dataIndex: "frequency",
        align: "right",
        render: (value) => (
          <label className="connection-tile-font">{value}</label>
        ),
      },
      {
        title: <span className="app-table-header">Actions</span>,
        render: renderActionButtons,
        align: "right",
      },
    ];
  }
}

export function mapSnowflakStatusToUI(snowflakeStatus) {
  switch (snowflakeStatus) {
    case "PENDING":
      return "Processing";
    case "PROCESSING":
    case "IN_PROGRESS":
    case "SENT_TO_EMAILCHECKER":
      return "Running";
    case "COMPLETED":
      return "Completed";
    case "ERROR_OCCURRED":
    case "FAILED":
      return "Failed";
    case "STOPPED_USER":
      return "Stopped";
    case "DELETED_USER":
      return "Deleted";
    default:
      return "Open";
  }
}

export function mapSnowflakeStatusToColor(snowflakeStatus) {
  switch (snowflakeStatus) {
    case "PENDING":
      return "#FAC748";
    case "IN_PROGRESS":
    case "PROCESSING":
    case "SENT_TO_EMAILCHECKER":
      return "#50E8B1";
    case "COMPLETED":
      return "#8B8B8B";
    case "ERROR_OCCURRED":
    case "FAILED":
      return "#F46666";
    case "STOPPED_USER":
    case "DELETED_USER":
      return "#F46666";
    default:
      return "#F46666";
  }
}

export const USER_ROLE_ENUM = {
  DEVELOPER: "DEVELOPER",
  MARKETING_ASSISTANCE: "MARKETING_ASSISTANCE",
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const FEATURES = {
  DASHBOARD_READ: "DASHBOARD_READ",
  API_SERVICES_READ: "API_SERVICES_READ",
  API_SERVICES_WRITE: "API_SERVICES_WRITE",
  API_SERVICES_EDIT: "API_SERVICES_EDIT",
  API_SERVICES_DELETE: "API_SERVICES_DELETE",
  ANALYTICS_READ: "ANALYTICS_READ",
  ANALYTICS_WRITE: "ANALYTICS_WRITE",
  ANALYTICS_EDIT: "ANALYTICS_EDIT",
  ANALYTICS_DELETE: "ANALYTICS_DELETE",
  CONNECTIONS_READ: "CONNECTIONS_READ",
  CONNECTIONS_WRITE: "CONNECTIONS_WRITE",
  CONNECTIONS_EDIT: "CONNECTIONS_EDIT",
  CONNECTIONS_DELETE: "CONNECTIONS_DELETE",
  USERS_READ: "USERS_READ",
  USERS_EDIT: "USERS_EDIT",
  USERS_WRITE: "USERS_WRITE",
  USERS_DELETE: "USERS_DELETE",
};

export function getAllowedRoleFeatures(role) {
  switch (role) {
    case USER_ROLE_ENUM.DEVELOPER:
      return [
        FEATURES.API_SERVICES_READ,
        FEATURES.API_SERVICES_WRITE,
        FEATURES.API_SERVICES_EDIT,
        FEATURES.API_SERVICES_DELETE,
        FEATURES.CONNECTIONS_READ,
        FEATURES.CONNECTIONS_WRITE,
        FEATURES.CONNECTIONS_EDIT,
        FEATURES.CONNECTIONS_DELETE,
      ];
    case USER_ROLE_ENUM.MARKETING_ASSISTANCE:
      return [
        FEATURES.DASHBOARD_READ,
        FEATURES.ANALYTICS_READ,
        FEATURES.ANALYTICS_WRITE,
        FEATURES.ANALYTICS_EDIT,
        FEATURES.ANALYTICS_DELETE,
      ];
    case USER_ROLE_ENUM.ADMIN:
    case USER_ROLE_ENUM.SUPER_ADMIN:
      return Object.keys(FEATURES);
    default:
      return [];
  }
}

export function getDefaultPageByRole(role) {
  switch (role) {
    case USER_ROLE_ENUM.DEVELOPER:
      return "/console/apiandservices";
    case USER_ROLE_ENUM.SUPER_ADMIN:
    case USER_ROLE_ENUM.ADMIN:
    case USER_ROLE_ENUM.MARKETING_ASSISTANCE:
      return "/console/dashboard";
    default:
      return "/console/dashboard";
  }
}

export function mapUserRoleToString(userRole) {
  switch (userRole) {
    case "DEVELOPER":
      return "Developer";
    case "MARKETING_ASSISTANCE":
      return "Marketing";
    case "ADMIN":
      return "Admin";
    case "SUPER_ADMIN":
      return "Owner";
    default:
      return "";
  }
}

export const DATA_ENDPOINTS = {
  AWS: 'AWS',
  OPTABLE: 'OPTABLE',
  SNOWFLAKE: 'SNOWFLAKE',
}

export function getGraphColor(index) {
  const colorSet = ['#2E70D2', '#F46666', '#FAC748', '#50E8B1', '#BD94BC', '#7FDBFF', '#0074D9', '#39CCCC', '#FFDC00', '#B10DC9'];
  return colorSet[index % colorSet.length];
}

export function getPieColors() {
  return ['#2E70D2', '#F46666', '#FAC748', '#50E8B1', '#BD94BC', '#7FDBFF', '#0074D9', '#39CCCC', '#FFDC00', '#B10DC9']
}

export function formatNumberWithCommas(num) {
  try {
    if (typeof num === 'string') {
      return parseInt(num).toLocaleString('en-US');
    }
    return num.toLocaleString('en-US');
  } catch (error) {
    return 0
  }

}
