import { Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import SideDrawer from "./Components/SideDrawer";
import AddApplication from "./Screens/AddApplication";
import Analytics from "./Screens/Analytics/AnalyticsV2";
import ConnectionList from "./Screens/Connections/ConnectionList";
import Connections from "./Screens/Connections/Connections";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Login from "./Screens/Login/Login";
import MobileView from "./Screens/MobileView/MobileView";
import ForgotPassword from "./Screens/Password/ForgotPassword";
import ResetPassword from "./Screens/Password/ResetPassword";
import SetPassword from "./Screens/Password/SetPassword";
import Profile from "./Screens/Profile/Profile";
import Step1 from "./Screens/SignUp/Step1";
import Step2 from "./Screens/SignUp/Step2";
import Step3 from "./Screens/SignUp/Step3";
import Step4 from "./Screens/SignUp/Step4";
import SnowflakeIntegrationCallback from "./Screens/SnowflakeIntegration/SnoflakeIntegrationCallback";
import SnowflakeIntegration from "./Screens/SnowflakeIntegration/SnowflakeIntegration";
import { SnowflakeSnapshotCallback } from "./Screens/SnowflakeIntegration/SnowflakeSnapshotCallback";
import Users from "./Screens/Users/Users";
import { userStore, UserStore } from "./Store/UserStore";
import { fetchUserDetails } from "./Utils/ApiActions";
import { FEATURES, getAllowedRoleFeatures } from "./Utils/Helper";
import { InfosumConnections } from "./Screens/Connections/InfosumConnections";
import { ContactForm } from "./Screens/ContactForm/ContactForm";
import { SelfServe } from "./Screens/SelfServe/SelfServe";
import { AWSConnections } from "./Screens/Connections/AWSConnections";
import { UIDToken } from "./Screens/Connections/UIDToken";
import { RampIDToken } from "./Screens/Connections/RampIDToken";
import { AWSDataEndPoint } from "./Screens/Connections/data-endpoints/AWSDataEndpoint";
import { ID5Token } from "./Screens/Connections/ID5Token";
import { GoogleAuthentication } from "./Screens/Connections/authentication-services/GoogleAuthentication";
import { OptableDataEndpoint } from "./Screens/Connections/data-endpoints/OptableDataEndpoint";
import { PianoAuthentication } from "./Screens/Connections/authentication-services/PianoAuthentication";
import { SnowflakeDataEndPoint } from "./Screens/Connections/data-endpoints/snowflakeDataEndpoint";
import { AuthOAuthentication } from "./Screens/Connections/authentication-services/AuthOAuthentication";
import { EUIDToken } from "./Screens/Connections/EUIDToken";
import { FirstIdToken } from "./Screens/Connections/FirstIDToken";
import {YahooConnectID} from "./Screens/Connections/YahooConnectID";

function RouterApp(props) {

  if (isMobile) {
    return (
      <Router>
        <Switch>
          <Route path="/" children={<MobileView></MobileView>}></Route>
        </Switch>
      </Router>
    );
  }
  return (
    <div>
      <UserStore>
        <InternalApp />
      </UserStore>
    </div>
  );
}

function InternalApp(props) {

  const [isRoleDefined, setIsRoleDefined] = useState(false);
  const userContext = useContext(userStore);

  useEffect(() => {
    getUserRole();
  }, [])

  async function getUserRole() {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      setIsRoleDefined(true);
      return;
    }
    try {
      const role = await fetchUserDetails();
      console.log(`The user role:: ${role}`);
      if (role) {
        userContext.actions.setUserRole(role);
        setIsRoleDefined(true);
        return;
      }
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authToken");
      localStorage.removeItem("publisherName");
      setIsRoleDefined(true);
    } catch (error) {
      console.log(`Unable to get the role of the user::`);
      console.log(error);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authToken");
      localStorage.removeItem("publisherName");
      setIsRoleDefined(true);
    }
  }

  return (
    <>
      {isRoleDefined && <Router>
        <Switch>
          <Route exact path="/console" children={<Login></Login>}></Route>
          <Route exact path="/console/signup" children={<Step1 />} />
          <Route exact path="/console/signup/step2" children={<Step2 />} />
          <Route exact path="/console/signup/step3" children={<Step3 />} />
          <Route exact path="/console/signup/step4" children={<Step4 />} />
          <Route
            exact
            path="/console/signup/password"
            children={<SetPassword />}
          />
          <Route
            exact
            path="/console/signup/resetpassword"
            children={<ResetPassword></ResetPassword>}
          />
          <Route exact path="/console/forgot" children={<ForgotPassword />} />
          <Route
            path="/console"
            render={() => {
              if (localStorage.getItem("authToken") !== null) {
                return (
                  <Switch>
                    <Route
                      exact
                      path="/console/dashboard"
                      children={<ProtectedRoute feature={FEATURES.DASHBOARD_READ}><Dashboard></Dashboard></ProtectedRoute>}
                    ></Route>
                    <Route
                      exact
                      path="/console/apiandservices"
                      children={<ProtectedRoute feature={FEATURES.API_SERVICES_READ}><App></App></ProtectedRoute>}
                    ></Route>
                    <Route
                      exact
                      path="/console/add"
                      children={<ProtectedRoute feature={FEATURES.API_SERVICES_WRITE}><AddApplication></AddApplication></ProtectedRoute>}
                    ></Route>
                    <Route
                      exact
                      path="/console/analytics"
                      children={<ProtectedRoute feature={FEATURES.ANALYTICS_READ}><Analytics /></ProtectedRoute>}
                    ></Route>
                    <Route
                      exact
                      path="/console/profile"
                      children={<Profile />}
                    ></Route>
                    <Route
                      exact
                      path="/console/snowflake-integration"
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><SnowflakeIntegration /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/snowflake-callback'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_WRITE}><SnowflakeIntegrationCallback /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/connections'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><Connections /></ProtectedRoute>}
                    ></Route>
                    <Route
                      exact
                      path='/console/connectionList'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><ConnectionList /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/snowflake-snapshot-callback'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><SnowflakeSnapshotCallback /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/users'
                      children={<ProtectedRoute feature={FEATURES.USERS_READ}><Users /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/infosum'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><InfosumConnections /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/aws-connection'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><AWSConnections /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/uid-token'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><UIDToken /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/ramp-id'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><RampIDToken /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/yahoo-connect-id'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><YahooConnectID /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/first-id'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><FirstIdToken /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/contact'
                      children={<ContactForm />}
                    />
                    <Route
                      exact
                      path='/console/id5'
                      children={<ID5Token />}
                    />
                    <Route
                      exact
                      path='/console/euid'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><EUIDToken /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/optable-data-endpoint'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><OptableDataEndpoint /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/self-serve'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><SelfServe /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/aws-data-endpoint'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><AWSDataEndPoint /></ProtectedRoute>}
                    />

                    <Route
                      exact
                      path='/console/snowflake-data-endpoint'
                      children={<ProtectedRoute feature={FEATURES.CONNECTIONS_READ}><SnowflakeDataEndPoint /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/google-authentication'
                      children={<ProtectedRoute
                        feature={FEATURES.CONNECTIONS_READ}
                      ><GoogleAuthentication /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/piano-authentication'
                      children={<ProtectedRoute
                        feature={FEATURES.CONNECTIONS_READ}
                      ><PianoAuthentication /></ProtectedRoute>}
                    />
                    <Route
                      exact
                      path='/console/autho-authentication'
                      children={<ProtectedRoute
                        feature={FEATURES.CONNECTIONS_READ}
                      ><AuthOAuthentication /></ProtectedRoute>}
                    />
                  </Switch>
                );
              } else {
                return <Login></Login>;
              }
            }}
          ></Route>
        </Switch>
      </Router>}
      {!isRoleDefined && <div style={{ display: 'flex', flex: 1, height: '100vh', justifyContent: 'center', alignItems: 'center' }}><Typography.Text>Loading...</Typography.Text></div>}
    </>
  )
}

function ProtectedRoute(props) {
  const userContext = useContext(userStore);
  const { userRole } = userContext.state;
  const userFeatures = getAllowedRoleFeatures(userRole);
  if (userFeatures.includes(props.feature)) {
    return props.children
  } else {
    return <></>
  }
}

export default RouterApp;
